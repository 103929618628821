import React from "react"
import { FaArrowRight } from "react-icons/fa"
import { RoundButton } from "../../components/buttons/round"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const water = require("../../images/water.jpeg")
const turbines = require("../../images/turbines.jpeg")

const PlatformsPage = () => (
  <Layout>
    <SEO
      title="AI Platforms"
      description="Various AI platforms and tools built by Flapmax."
      lang="en"
      meta={[]}
    />

    <div className="hero-wrapper platforms">
      <div className="w-100">
        <h1 className="mb-3 text-shadow-sm">Accelerated AI Platforms</h1>
        <h3 className="mb-0">
          To give you the cutting edge performance and functionality your
          business needs.
        </h3>
      </div>
    </div>

    {/* Content Block */}
    <section>
      <div className="container">
        <div className="mb-5 d-flex justify-content-between">
          {/* <RoundButton caption="Our Technologies" btnChild={<FaArrowRight />} /> */}
        </div>
        <div className="row">
          <div className="my-3 col-lg-6">
            <div className="card h-100">
              <div
                className="px-4 py-2 mb-5 d-flex align-items-end"
                style={{
                  minHeight: "300px",
                  backgroundImage: `url(${turbines})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <h2 className="px-2 text-light bold text-shadow-sm bg-white-faded">
                  Measure
                </h2>
              </div>
              <p className="mb-4">
                Measure optimizes AI models and measures their performance
                against targeted hardware platforms before readying them for
                deployment
              </p>
              <div className="d-flex justify-content-end">
                <RoundButton
                  link="/platforms/measure"
                  target="_blank"
                  caption="Go to Measure"
                  btnSize="sm"
                  btnChild={<FaArrowRight />}
                />
              </div>
            </div>
          </div>
          <div className="my-3 col-lg-6">
            <div className="card h-100">
              <div
                className="px-4 py-2 mb-5 d-flex align-items-end"
                style={{
                  minHeight: "300px",
                  backgroundImage: `url(${water})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <h2 className="px-2 text-light bold text-shadow-sm bg-white-faded">
                  Coral Imaging
                </h2>
              </div>
              <p className="mb-4">
                Coral Imaging is a privacy-preserving, multi-institutional
                collaboration platform for 3D medical imaging over high-speed
                networks such as 5G
              </p>
              <div className="d-flex justify-content-end">
                <RoundButton
                  link="/platforms/coral-imaging"
                  target="_blank"
                  caption="Go to Coral Imaging"
                  btnSize="sm"
                  btnChild={<FaArrowRight />}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default PlatformsPage
